export const types: { [key: string]: { icon: string, label: string, color: string } } = {
  飲食店: {
    icon: 'map/utensils.png',
    label: 'カフェ・飲食店',
    color: 'rgb(194, 24, 91)'
  },
  コンビニ: {
    icon: 'map/shop.png',
    label: 'コンビニ',
    color: 'rgb(245, 124, 0)'
  },
  温泉: {
    icon: 'map/onsen.png',
    label: '日帰り温泉',
    color: 'rgb(230, 81, 0)'
  },
  宿: {
    icon: 'map/hotel.png',
    label: 'サイクリスト向けの宿',
    color: 'rgb(121, 85, 72)'
  },
  その他: {
    icon: 'map/flag.png',
    label: 'その他の施設',
    color: 'rgb(2, 136, 209)'
  },
  調査中: {
    icon: 'map/info.png',
    label: '調査中',
    color: 'rgb(124, 179, 66)'
  }
}
