import React from 'react'
import { Marker } from '@react-google-maps/api'

type CurrentPositionMarkerProps = {
  position: google.maps.LatLngLiteral | null
}

function CurrentPositionMarker (props: CurrentPositionMarkerProps) {
  const { position } = props

  if (position == null) return <></>

  return (
    <Marker position={position} icon={'map/bicycle.png'} />
  )
}

export default CurrentPositionMarker
