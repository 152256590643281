import React, { useState } from 'react'
import './Autocomplete.css'
import { Autocomplete } from '@react-google-maps/api'

function PlaceAutocomplete (props: { loadPlace: (place: google.maps.places.PlaceResult) => void }) {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null)

  function onPlaceChanged () {
    if (autocomplete == null) return
    const place = autocomplete.getPlace()
    console.log(place)

    props.loadPlace(place)
  }

  return (
    <Autocomplete
      className='autocomplete'
      onLoad={(autocomplete) => setAutocomplete(autocomplete)}
      onPlaceChanged={onPlaceChanged}
    >
      <input
        type='text'
        placeholder='Google マップで検索'
      />
    </Autocomplete>
  )
}

export default PlaceAutocomplete
