import React, { useState } from 'react'
import { GoogleMap, Marker } from '@react-google-maps/api'
import './Map.css'
import Autocomplete from './Autocomplete'
import PlaceList from './PlaceList'
import { types as placeTypes } from './Icons'
import InfoWindow from './InfoWindow'
import { prefectures as prefs, getPrefecture, getPrefectureNo } from './Prefectures'
import CurrentPositionMarker from './CurrentPositionMarker'
import CurrentPositionButton from './CurrentPositionButton'
import { collection, query, orderBy, startAt, endAt, getDocs, DocumentData } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { geohashQueryBounds } from 'geofire-common'

const googleMapsCenter: google.maps.LatLngLiteral = {
  lat: 35.681236,
  lng: 139.767125
}

const googleMapsZoomLevel = 8

const prefectureZoomLevel = 9

const currentPositionZoomLevel = 15

const offsetWidth = 150

const mdWidth = 768

const mapContainerStyle = {
  height: '100%',
  width: '100%'
}

const placeField = [
  'place_id',
  'name',
  'geometry.location',
  'address_components',
  'formatted_address',
  'formatted_phone_number',
  'url',
  'plus_code',
  'types'
  // Contact Data レベルは追加料金なので取らない
  // 'website',
  // Atmosphere レベルは追加料金なので取らない
  // 'rating',
  // 'user_ratings_total'
]

function offsetLatLng (map: google.maps.Map, zoom: number, latlng: google.maps.LatLngLiteral): google.maps.LatLngLiteral | null {
  const projection = map.getProjection()
  if (!projection) return null

  const center = projection.fromLatLngToPoint(new google.maps.LatLng(latlng))
  if (center === null) return null

  const offset = offsetWidth / Math.pow(2, zoom)
  const offsetCenter = projection.fromPointToLatLng(new google.maps.Point(center.x - offset, center.y))
  if (offsetCenter === null) return null

  return { lat: offsetCenter.lat(), lng: offsetCenter.lng() }
}

function PlaceMap () {
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [zoom, setZoom] = useState(googleMapsZoomLevel)
  const [width, setWidth] = useState(window.innerWidth)
  const [placeService, setPlaceService] = useState<google.maps.places.PlacesService | null>(null)
  const [infoWindow, setInfoWindow] = useState(<></>)
  const [selectedPlaceId, setSelectedPlaceId] = useState<string | null>(null)
  const [places, setPlaces] = useState<DocumentData[]>([])
  const [prefectures, setPrefectures] = useState(prefs)
  const [currentPosition, setCurrentPosition] = useState<google.maps.LatLngLiteral | null>(null)

  const firestore = useFirestore()
  const analytics = getAnalytics()

  function setCenter (latlng: google.maps.LatLngLiteral, newZoom?: number) {
    if (map === null) return

    if (newZoom) {
      setZoom(newZoom)
    }

    if (mdWidth <= width) {
      const position = offsetLatLng(map, newZoom ?? zoom, latlng)
      if (position === null) {
        return
      }
      map.panTo(position)
    } else {
      map.panTo(latlng)
    }
  }

  function setPlaceId (placeId: string) {
    setSelectedPlaceId(placeId)

    if (placeService == null) return

    const request = {
      placeId: placeId,
      fields: placeField
    }

    placeService.getDetails(request, (place, status) => {
      if (status !== 'OK' || place?.geometry?.location == null) return

      const location = place.geometry.location
      const position = { lat: location.lat(), lng: location.lng() }

      // iPhone の Safari で表示がズレるのでリセットしてから表示している
      setInfoWindow(<></>)
      setInfoWindow(
        <InfoWindow
          position={position}
          place={place}
          onCloseClick={() => {
            setSelectedPlaceId(null)
            setInfoWindow(<></>)
          }}
          onGoogleMapLinkClick={() => {
            logEvent(
              analytics,
              'on_click_google_map_link',
              {
                place_id: place.place_id,
                name: place.name,
                position: position
              }
            )
          }}
        />
      )
    })
  }

  function loadPlace (place: google.maps.places.PlaceResult) {
    const placeId = place.place_id
    if (!placeId) return

    const location = place.geometry?.location
    if (location == null) return

    const position = { lat: location.lat(), lng: location.lng() }

    setCenter(position)
    setPlaceId(placeId)
  }

  function onLoad (gmap: google.maps.Map) {
    setMap(gmap)
    const service = new google.maps.places.PlacesService(gmap)
    setPlaceService(service)
  }

  function onClick (event: google.maps.MapMouseEvent) {
    event.stop()

    const placeId = (event as google.maps.IconMouseEvent).placeId
    if (placeId == null) return

    setPlaceId(placeId)

    logEvent(analytics, 'on_click', { place_id: placeId })
  }

  function onZoomChanged () {
    const zoom = map?.getZoom()
    if (zoom == null) return

    setZoom(zoom)
  }

  async function mapDocs (map: google.maps.Map): Promise<[number[], DocumentData[]] | null> {
    const bounds = map.getBounds()
    if (!bounds) return null

    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()

    const centerLatLng = map.getCenter()
    if (!centerLatLng) return null

    const center = [centerLatLng.lat(), centerLatLng.lng()]
    const distance = google.maps.geometry.spherical.computeDistanceBetween(ne, sw) * 0.5

    const promises = []
    const bs = geohashQueryBounds(center, distance)
    for (const b of bs) {
      const placesCollection = collection(firestore, 'places')
      const placesQuery = query(placesCollection, orderBy('geohash'), startAt(b[0]), endAt(b[1]))
      const placeSnapshot = getDocs(placesQuery)
      promises.push(placeSnapshot)
    }

    const docs = []
    const snapshots = await Promise.all(promises)
    for (const snap of snapshots) {
      for (const doc of snap.docs) {
        const position = doc.get('position')
        if (bounds.contains(position)) {
          const data = doc.data()
          data.prefecture = getPrefecture(data.address_components)
          docs.push(data)
        }
      }
    }

    docs.sort((a, b) => {
      // 都道府県
      const prefA = getPrefectureNo(a.prefecture)
      const prefB = getPrefectureNo(b.prefecture)
      if (prefA < prefB) {
        return -1
      }
      if (prefA > prefB) {
        return 1
      }
      // タイプ
      if (a.type < b.type) {
        return 1
      }
      if (a.type > b.type) {
        return -1
      }
      // 名称
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    return [center, docs]
  }

  async function onIdle () {
    if (map === null) return

    setWidth(window.innerWidth)

    if (prefectureZoomLevel <= zoom) {
      setPrefectures([])

      const centerDocs = await mapDocs(map)
      if (centerDocs) {
        setPlaces(centerDocs[1])
      }
    } else {
      setPrefectures(prefs)
      setPlaces([])
    }
  }

  function onClickCurrentPosition (position: google.maps.LatLngLiteral) {
    setCurrentPosition(position)
    setCenter(position, zoom < prefectureZoomLevel ? currentPositionZoomLevel : zoom)
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={googleMapsCenter}
      zoom={zoom}
      onLoad={onLoad}
      onClick={onClick}
      onZoomChanged={onZoomChanged}
      onIdle={onIdle}
      options={{ disableDefaultUI: true }}
    >
      <div className='map'>
        <div className='header'>
          <h1 className='title d-block d-sm-none'>サイクルラックのあるお店</h1>
          <Autocomplete loadPlace={loadPlace} />
        </div>
        <div className={prefectureZoomLevel <= zoom ? 'expand' : 'contract'}>
          <PlaceList
            selectedPlaceId={selectedPlaceId}
            places={places}
            onClick={(place) => {
              setCenter(place.position)
              setPlaceId(place.place_id)
              logEvent(
                analytics,
                'on_click_list',
                {
                  place_id: place.place_id,
                  name: place.name,
                  position: place.position
                }
              )
            }}
          >
            <div className='current-position-button d-grid'>
              <CurrentPositionButton onClick={onClickCurrentPosition} />
            </div>
          </PlaceList>
        </div>
        {infoWindow}
        {prefectures.map(([name, position]) => {
          return (
            <Marker
              key={name}
              icon='map/prefecture.png'
              position={position}
              label={{ text: name, color: 'white', fontSize: '0.7em', fontWeight: '900' }}
              onClick={(e: google.maps.MapMouseEvent) => {
                const zoom = prefectureZoomLevel + 1
                setCenter(position, zoom)
                logEvent(
                  analytics,
                  'on_click_prefecture',
                  {
                    name: name
                  }
                )
              }
              }
            />
          )
        })}
        {places.map((place) => {
          // https://developers.google.com/maps/documentation/javascript/examples/marker-modern
          const placeType = placeTypes[place.type]
          return (
            <Marker
              key={place.place_id}
              icon={placeType.icon}
              position={place.position}
              onClick={(e: google.maps.MapMouseEvent) => {
                setPlaceId(place.place_id)
                logEvent(
                  analytics,
                  'on_click_marker',
                  {
                    place_id: place.place_id,
                    name: place.name,
                    position: place.position
                  }
                )
              }}
            />
          )
        })}
        <CurrentPositionMarker position={currentPosition} />
        <div className='footer d-grid'>
          <CurrentPositionButton className='d-block d-sm-none' onClick={onClickCurrentPosition} />
        </div>
      </div>
    </GoogleMap>
  )
}

export default PlaceMap
