import React from 'react'
import './PlaceList.css'
import { DocumentData } from 'firebase/firestore'
import { types } from './Icons'
import SignOutButton from '../firebase/SignOutButton'

function PlaceList (
  props: {
    children?: React.ReactNode,
    selectedPlaceId: string | null,
    places: DocumentData[],
    onClick: (place: DocumentData) => void
  }
) {
  const { children, selectedPlaceId, places, onClick } = props

  let oldPrefecture = ''

  return (
    <div className='d-none d-md-block'>
      <div className='place-list'>
        <div className='title'>サイクルラックのあるお店</div>
        <div className='list'>
          <ul>
            {places.map((place) => {
              const placeType = types[place.type]
              let prefElm
              if (oldPrefecture === place.prefecture) {
                prefElm = <></>
              } else {
                oldPrefecture = place.prefecture
                prefElm = <li className='prefecture'>{place.prefecture}</li>
              }
              return (
                <React.Fragment key={place.place_id}>
                  {prefElm}
                  <li
                    className={'place' + (selectedPlaceId === place.place_id ? ' selected' : '')}
                    onClick={(e) => { onClick(place) }
                    }
                  >
                    <img src={placeType.icon} />
                    {place.name}
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
        <div className='action'>
          {children}
          <SignOutButton />
        </div>
      </div>
    </div>
  )
}

export default PlaceList
