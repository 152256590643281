export const prefectures: [string, { lat: number, lng: number }][] = [
  ['北海道', { lat: 43.420962, lng: 142.781281 }],
  ['青森県', { lat: 40.699056, lng: 140.726924 }],
  ['岩手県', { lat: 39.511756, lng: 141.399429 }],
  ['宮城県', { lat: 38.381565, lng: 140.941651 }],
  ['秋田県', { lat: 39.678886, lng: 140.392163 }],
  ['山形県', { lat: 38.497668, lng: 140.108578 }],
  ['福島県', { lat: 37.418743, lng: 140.231252 }],
  ['茨城県', { lat: 36.304975, lng: 140.385361 }],
  ['栃木県', { lat: 36.683168, lng: 139.817955 }],
  ['群馬県', { lat: 36.481484, lng: 138.923514 }],
  ['埼玉県', { lat: 36.003615, lng: 139.368331 }],
  ['千葉県', { lat: 35.473969, lng: 140.222304 }],
  ['東京都', { lat: 35.686991, lng: 139.539242 }],
  ['神奈川県', { lat: 35.403620, lng: 139.349213 }],
  ['新潟県', { lat: 37.368708, lng: 138.888731 }],
  ['富山県', { lat: 36.607484, lng: 137.287216 }],
  ['石川県', { lat: 36.772391, lng: 136.778841 }],
  ['福井県', { lat: 35.812610, lng: 136.184399 }],
  ['山梨県', { lat: 35.609615, lng: 138.628685 }],
  ['長野県', { lat: 36.149935, lng: 138.024588 }],
  ['岐阜県', { lat: 35.778724, lng: 137.057877 }],
  ['静岡県', { lat: 35.033282, lng: 138.312185 }],
  ['愛知県', { lat: 35.002511, lng: 137.208724 }],
  ['三重県', { lat: 34.484291, lng: 136.432514 }],
  ['滋賀県', { lat: 35.225920, lng: 136.139617 }],
  ['京都府', { lat: 35.220152, lng: 135.517902 }],
  ['大阪府', { lat: 34.598366, lng: 135.545261 }],
  ['兵庫県', { lat: 35.068625, lng: 134.794436 }],
  ['奈良県', { lat: 34.292803, lng: 135.896845 }],
  ['和歌山県', { lat: 33.848677, lng: 135.416815 }],
  ['鳥取県', { lat: 35.391534, lng: 133.850276 }],
  ['島根県', { lat: 34.975087, lng: 132.423277 }],
  ['岡山県', { lat: 34.861972, lng: 133.833990 }],
  ['広島県', { lat: 34.588492, lng: 132.792091 }],
  ['山口県', { lat: 34.226281, lng: 131.430559 }],
  ['徳島県', { lat: 33.915461, lng: 134.273465 }],
  ['香川県', { lat: 34.219680, lng: 133.979044 }],
  ['愛媛県', { lat: 33.661193, lng: 132.838719 }],
  ['高知県', { lat: 33.507085, lng: 133.364174 }],
  ['福岡県', { lat: 33.599679, lng: 130.682867 }],
  ['佐賀県', { lat: 33.279436, lng: 130.118294 }],
  ['長崎県', { lat: 32.955619, lng: 129.715641 }],
  ['熊本県', { lat: 32.587230, lng: 130.807836 }],
  ['大分県', { lat: 33.203809, lng: 131.411655 }],
  ['宮崎県', { lat: 32.200128, lng: 131.353483 }],
  ['鹿児島県', { lat: 31.355836, lng: 130.410976 }],
  ['沖縄県', { lat: 26.477084, lng: 127.922927 }]
]

function createPrefectureNo () {
  const prefNo: { [key: string]: number } = {}
  for (let n = 0; n < prefectures.length; n++) {
    prefNo[prefectures[n][0]] = n
  }
  return prefNo
}

const prefecturesNo = createPrefectureNo()

export type AddressComponent = {
  // eslint-disable-next-line camelcase
  long_name: string,
  // eslint-disable-next-line camelcase
  short_name: string,
  types: [string]
}

export function getPrefecture (address: [AddressComponent]) {
  for (const addr of address) {
    if (addr.types.includes('administrative_area_level_1')) {
      return addr.short_name
    }
  }
  return null
}

export function getPrefectureNo (pref: string) {
  if (pref === null || !(pref in prefecturesNo)) return 99
  return prefecturesNo[pref]
}
