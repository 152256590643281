import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './SignIn.css'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { useSigninCheck } from 'reactfire'

export default function SignIn () {
  const navigate = useNavigate()
  const auth = getAuth()
  const [isLoading, setLoading] = useState(false)
  const { status, data: signInCheckResult } = useSigninCheck()

  useEffect(() => {
    if (status === 'loading' || !signInCheckResult.signedIn) return

    navigate('/', { replace: true })
  })

  function onClick () {
    if (isLoading) return

    setLoading(true)

    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/', { replace: true })
      })
      .catch((error) => console.log(error))
  }

  return (
    <div className='sign-in'>
      <Container>
        <Row>
          <Col>
            <h1>サイクルラックのあるお店</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant='primary'
              disabled={isLoading}
              onClick={onClick}
            >{isLoading ? '処理中...' : 'Google ログイン'}</Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
