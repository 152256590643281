import React from 'react'
import { Button } from 'react-bootstrap'
import { getAuth, signOut } from 'firebase/auth'
import { useSigninCheck } from 'reactfire'
import { useNavigate } from 'react-router-dom'

export default function SignOutButton () {
  const { status, data: signInCheckResult } = useSigninCheck()
  const navigate = useNavigate()
  const auth = getAuth()

  function onClick () {
    signOut(auth)
      .then(() => {
        navigate('/', { replace: true })
      })
      .catch((error) => console.log(error))
  }

  if (status === 'loading') return <></>
  if (!signInCheckResult.signedIn) return <></>

  return (
    <div className='sign-out-button d-grid'>
      <Button
        variant='secondary'
        size='sm'
        onClick={onClick}
      >ログアウト</Button>
    </div>
  )
}
