import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

type CurrentPositionMarkerProps = {
  className?: string,
  onClick: (position: google.maps.LatLngLiteral) => void
}

function CurrentPositionButton (props: CurrentPositionMarkerProps) {
  const { className, onClick } = props

  const [processing, setProcessing] = useState(false)

  function onClickButton () {
    setProcessing(true)
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setProcessing(false)
        onClick({ lat: position.coords.latitude, lng: position.coords.longitude })
      },
      console.log
    )
  }

  return (
    <Button
      className={className}
      variant='primary'
      size='sm'
      disabled={processing}
      onClick={onClickButton}
    >
      {processing ? '現在地を取得中...' : '現在地を表示'}
    </Button>
  )
}

export default CurrentPositionButton
