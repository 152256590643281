import React from 'react'
import { LoadScript } from '@react-google-maps/api'
import './App.css'
import FirebaseApp from './firebase/FirebaseApp'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Map from './places/Map'
import SignIn from './firebase/SignIn'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const googleMapsLibraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = ['places', 'geometry']

function App () {
  return (
    <LoadScript
      googleMapsApiKey={googleMapsApiKey ?? ''}
      libraries={googleMapsLibraries}
    >
      <FirebaseApp>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Map />} />
            <Route path='/sign-in' element={<SignIn />} />
          </Routes>
        </BrowserRouter>
      </FirebaseApp>
    </LoadScript >
  )
}

export default App
