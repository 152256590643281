import React, { useEffect } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { getAnalytics, logEvent } from 'firebase/analytics'
import {
  FirebaseAppProvider,
  AuthProvider,
  FirestoreProvider,
  AnalyticsProvider,
  useFirebaseApp,
  useUser,
  useFirestore,
  useAnalytics
} from 'reactfire'
import Loading from '../Loading'

const firebaseConfigJSON = process.env.REACT_APP_FIREBASE_CONFIG ?? '{}'
const firebaseConfig = JSON.parse(firebaseConfigJSON)

type Props = {
  children: React.ReactNode
}

function AnalyticsPageLogger () {
  const analytics = useAnalytics()

  // https://github.com/FirebaseExtended/reactfire/blob/main/docs/use.md#log-page-views-to-google-analytics-for-firebase-with-react-router
  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.href })
  }, [location.href])

  return null
}

function FirebaseAuthCheck (props: Props) {
  const auth = getAuth()
  const firestore = useFirestore()
  const { status: userStatus, data: user } = useUser()

  if (userStatus === 'loading') return <Loading />

  useEffect(() => {
    if (!user) return

    const checkAdmin = async () => {
      const adminRef = doc(firestore, 'admins', user.uid ?? '-')
      const adminSnap = await getDoc(adminRef)
      if (!adminSnap.exists()) {
        await signOut(auth)
      }
    }

    checkAdmin()
  })

  return <>{props.children}</>
}

function FirebaseComponent (props: Props) {
  const app = useFirebaseApp()
  const auth = getAuth()
  const firestore = getFirestore(useFirebaseApp())

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestore}>
          <FirebaseAuthCheck>
            {props.children}
          </FirebaseAuthCheck>
        </FirestoreProvider>
      </AuthProvider>
      <AnalyticsPageLogger />
    </AnalyticsProvider>
  )
}

function FirebaseApp (props: Props) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponent>
        {props.children}
      </FirebaseComponent>
    </FirebaseAppProvider>
  )
}

export default FirebaseApp
