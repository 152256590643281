import React, { useState, useEffect } from 'react'
import { InfoWindow } from '@react-google-maps/api'
import { doc, setDoc, deleteDoc, serverTimestamp, DocumentSnapshot } from 'firebase/firestore'
import { useFirestore, useFirestoreDoc, useSigninCheck } from 'reactfire'
import { geohashForLocation } from 'geofire-common'
import Autolinker from 'autolinker'
import { types } from './Icons'
import './InfoWindow.css'
import Loading from '../Loading'

const defaultType = Object.keys(types)[0]

const autolinker = new Autolinker()

type WindowProps = {
  position: google.maps.LatLngLiteral,
  place: google.maps.places.PlaceResult,
  placeSnap: DocumentSnapshot,
  onCloseClick: () => void,
  onGoogleMapLinkClick: () => void,
  children?: React.ReactNode
}

function Window (props: WindowProps) {
  const { position, place, placeSnap, onCloseClick, onGoogleMapLinkClick } = props

  const type = placeSnap.exists() ? placeSnap.get('type') : null
  const placeType = type in types ? types[type] : null
  console.log(placeType)

  return (
    < InfoWindow
      position={position}
      onCloseClick={onCloseClick}
    >
      <div className='place-detail-info-window'>
        <div className='header'>
          <div className='icon'>
            {placeType && <img src={placeType.icon} />}
          </div>
          <div className='title'>
            <div className='name'>
              {place.name}
            </div>
            <div className='type'>
              {placeType && placeType.label}
            </div>
          </div>
        </div>
        {props.children}
        <div className='detail'>
          <div className='title'>Google マップからの詳細情報</div>
          <div className='address'>{place.formatted_address}</div>
          <div>
            <a
              href={place.url}
              target='_blank'
              rel='noreferrer'
              onClick={onGoogleMapLinkClick}
            >Google マップで見る</a>
          </div>
        </div>
      </div>
    </InfoWindow >
  )
}

function UserWindow (props: WindowProps) {
  const { placeSnap } = props

  const [description, setDescription] = useState('')

  useEffect(() => {
    if (placeSnap?.exists()) {
      setDescription(placeSnap.get('description'))
    } else {
      setDescription('')
    }
  }, [placeSnap])

  if (placeSnap.exists()) {
    return (
      <Window {...props}>
        <div className='description'>
          <div className='title'>サイクルラックのあるお店</div>
          <div dangerouslySetInnerHTML={{ __html: autolinker.link(description).replace(/\r?\n/g, '<br />') }} />
        </div>
      </Window>
    )
  } else {
    return <Window {...props}></Window>
  }
}

function AdminWindow (props: WindowProps) {
  const { place, placeSnap } = props

  const [type, setType] = useState(defaultType)
  const [description, setDescription] = useState('')
  const [changed, setChanged] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  useEffect(() => {
    setChanged(false)
    setConfirmed(false)
    if (placeSnap?.exists()) {
      setType(placeSnap.get('type'))
      setDescription(placeSnap.get('description'))
    } else {
      setType(defaultType)
      setDescription('')
    }
  }, [placeSnap])

  function addPlace (event: React.MouseEvent<HTMLInputElement>) {
    if (place.geometry?.location?.lat() == null) return
    if (place.geometry?.location?.lng() == null) return

    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()

    const geohash = geohashForLocation([lat, lng])
    const position = { lat: lat, lng: lng }

    let placeDoc: { [key: string]: any } = {
      // Place API
      name: place.name,
      place_id: place.place_id,
      position: position,
      geohash: geohash,
      plus_code: place.plus_code,
      types: place.types,
      url: place.url,
      address_components: place.address_components,
      formatted_address: place.formatted_address,
      formatted_phone_number: place.formatted_phone_number,
      // Contact Data レベルは追加料金なので取らない
      // website: place.website,
      // Atmosphere レベルは追加料金なので取らない
      // rating: place.rating,
      // user_ratings_total: place.user_ratings_total,
      // 追加情報
      description: description,
      type: type,
      // 更新日
      updated_at: serverTimestamp()
    }

    for (const key in placeDoc) {
      if (!placeDoc[key]) {
        placeDoc[key] = ''
      }
    }

    if (!placeSnap.exists()) {
      // 作成日
      placeDoc = { created_at: serverTimestamp(), ...placeDoc }
    }

    console.log('set', place?.place_id, placeDoc)
    setDoc(placeSnap.ref, placeDoc, { merge: true })
  }

  function renderDelete () {
    if (confirmed) {
      return (
        <>
          <div
            className='action'
            onClick={() => deleteDoc(placeSnap.ref)}
          >× 本当に地図から削除する</div>
          <div
            className='action delete'
            onClick={() => {
              setConfirmed(false)
            }}
          >＋ キャンセル</div>
        </>
      )
    } else {
      return (
        <div
          className='action delete'
          onClick={() => setConfirmed(true)}
        >× 地図から削除</div>
      )
    }
  }

  function renderLinks () {
    if (placeSnap.exists()) {
      if (changed) {
        return (
          <div className='actions'>
            <div className='action' onClick={addPlace}>＋ 地図を更新</div>
            {renderDelete()}
          </div>
        )
      } else {
        return (
          <div className='actions'>
            {renderDelete()}
          </div>
        )
      }
    } else {
      return (
        <div className='actions'>
          <div className='action' onClick={addPlace}>＋ 地図に登録</div>
        </div>
      )
    }
  }

  const placeType = types[type]

  return (
    <Window {...props}>
      <div className='form'>
        <div className='description'>
          <div className='title'>サイクルラック情報</div>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value)
              setChanged(true)
              setConfirmed(false)
            }}
          />
        </div>
        <div className='type'>
          <span className='icon'><img src={placeType.icon} /></span>
          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value)
              setChanged(true)
              setConfirmed(false)
            }}
          >
            {Object.keys(types).map((type) => (
              <option key={type} value={type}>{types[type].label}</option>
            ))}
          </select>
        </div>
        {renderLinks()}
      </div>
    </Window>
  )
}

type PlaceInfoWindowProps = {
  position: google.maps.LatLngLiteral,
  place: google.maps.places.PlaceResult,
  onCloseClick: () => void,
  onGoogleMapLinkClick: () => void
}

function PlaceInfoWindow (props: PlaceInfoWindowProps) {
  const { place, position } = props

  if (place.place_id == null) return <></>
  const placeId = place.place_id

  const firestore = useFirestore()
  const placeRef = doc(firestore, 'places', placeId)
  const { status: docStatus, data: placeSnap } = useFirestoreDoc(placeRef)
  const { status: userStatus, data: signInCheckResult } = useSigninCheck()

  if (userStatus === 'loading' || docStatus === 'loading' || placeSnap === null) return <Loading />

  if (signInCheckResult.signedIn) {
    return <AdminWindow
      position={position}
      place={place}
      placeSnap={placeSnap}
      onCloseClick={props.onCloseClick}
      onGoogleMapLinkClick={props.onGoogleMapLinkClick}
    />
  } else {
    return <UserWindow
      position={position}
      place={place}
      placeSnap={placeSnap}
      onCloseClick={props.onCloseClick}
      onGoogleMapLinkClick={props.onGoogleMapLinkClick}
    />
  }
}

export default PlaceInfoWindow
